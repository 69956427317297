<template>
   <div class="vme-modal">
    <div class="vme-modal-content">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-end align-items-center">
          <h3 style="margin-right:1em">Möchten Sie die Kalkulation löschen?</h3>
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
        <div v-if="alert.status" class="text-danger text-center">
          {{ alert.msg }}
        </div>
      </div>
      <div class="vme-modal-content-body">
          <div class="d-flex mt-4 w-100">
              <span @click="closeModal" class="abb py-2">Nein</span>
              <button class="btn btn-primary btn-default" @click="deleted">Ja</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue'
export default {
  props: ['item'],
  components: {
    PhX
  },
  data () {
    return {
      alert: {
        status: false,
        msg: null
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closed')
    },
    deleted () {
      this.$emit('deleted', this.item)
    }
  }
}
</script>
<style>
.abb {
  margin-right:23px;
  text-decoration:underline;
  cursor: pointer;
}
</style>

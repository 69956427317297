<template>
  <div class="content m-0">
    <perfect-scrollbar   class="w-100" ref="ps">
      <div class="container-fluid px-4 h-100">
        <div class="row  h-100 align-items-start">
          <div class="col-12 ">
            <div class="d-flex w-100 justify-content-between align-items-center mt-3">
                <span class="back-holder" @click="goBack">
                  <ph-arrow-left weight="bold" :size="28" />
                </span>
                <h3 class="text-color-gray system-title text-uppercase pe-3 mb-0 pb-0">SYSTEM</h3>
                <div class="page-header-tab">
                    <ul>
                        <li  ><a :class="{active: selectTab === 4}" href="#" @click.prevent="changeTab(4)">Kalkulationen Lieferant/Modell</a></li>
                        <li v-if="showTab('Gesellschafter')" ><a :class="{active: selectTab === 1}" href="#" @click.prevent="changeTab(1)">Hauskalkulation</a></li>
                        <li v-if="showTab('vme')" ><a :class="{active: selectTab === 2}"  href="#" @click.prevent="changeTab(2)">Preislisten Generierung</a></li>
                        <li v-if="showTab('users')" ><a :class="{active: selectTab === 3}"  href="#" @click.prevent="changeTab(3)">Benutzerverwaltung</a></li>
                    </ul>
                </div>
            </div>
          </div>
          <div class="col-12 h-100">
            <div class="page-wrapper h-100" :class="{'m-0': selectTab === 3}">
              <div class="page-wrapper-body h-100" :style=" selectTab === 3 ? 'margin-top: 20px !important' : null">
                 <component @scroll="$refs.ps.$el.scrollTop = 0"   :is="getTabComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>

  </div>
</template>

<script>
import Firmen from '@/components/system/Firmen.vue'
import NewCalc from '@/components/system/NewCalc.vue'
import Vme from '@/components/system/VME.vue'
import Users from '@/components/system/Users.vue'
import { mapMutations, mapGetters } from 'vuex'
import { PhArrowLeft } from 'phosphor-vue'
export default {
  components: {
    Firmen,
    Vme,
    NewCalc,
    Users,
    PhArrowLeft
  },
  data () {
    return {
      sorting: 'AZ',
      selectTab: 1,
      opened: 1,
      openModal: false
    }
  },
  computed: {
    ...mapGetters(['getUserRole', 'getUserOneRole', 'getSystemTab']),
    getTabComponent () {
      if (this.selectTab === 1) {
        return 'Firmen'
      }
      if (this.selectTab === 2) {
        return 'Vme'
      }
      if (this.selectTab === 3) {
        return 'Users'
      }
      if (this.selectTab === 4) {
        return 'NewCalc'
      }
      return 'Firmen'
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_SYSTEM_TAB']),
    showTab (tabname) {
      const roles = this.getUserRole
      if (tabname === 'Gesellschafter') {
        const findRoleIndex = roles.findIndex(item => item === 'isCalculationUser')
        if (findRoleIndex === -1) {
          return false
        }
        return true
      }
      if (tabname === 'vme') {
        const findRoleIndex = roles.findIndex(item => item === 'isAppAdminUser')
        if (findRoleIndex === -1) {
          return false
        }
        return true
      }
      if (tabname === 'users') {
        const findRoleIndex = roles.findIndex(item => item === 'isUserManagementUser')
        if (findRoleIndex === -1) {
          return false
        }
        return true
      }
    },
    changeTab (par) {
      if (par === this.selectTab) {
        return
      }
      this.SET_SYSTEM_TAB(par)
      this.selectTab = par
    },
    openDetail (idx) {
      if (idx === this.opened) {
        this.opened = 0
        return
      }
      this.opened = idx
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const isAppAdmin = this.getUserOneRole('isAppAdminUser')
    const isUserManagement = this.getUserOneRole('isUserManagementUser')
    const isCalculationUser = this.getUserOneRole('isCalculationUser')
    if (isCalculationUser === -1 && isUserManagement === -1 && isAppAdmin === -1) {
      return this.$router.push('/access-denied')
    }
    const data = [
      {
        title: 'Rabatte'
      }, {
        title: ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
    this.selectTab = this.getSystemTab
    if (!this.selectTab) {
      this.getUserRole.map(item => {
        if (item === 'isAppAdminUser') {
          this.selectTab = 2
        }
        if (item === 'isUserManagementUser') {
          this.selectTab = 3
        }
        if (item === 'isCalculationUser') {
          this.selectTab = 1
        }
      })
    }
  }
}
</script>

<style>
.system-title{
  font-weight: 500;
  font-size: 30px;
}
</style>

<template>
  <div class="vme-modal">
    <div class="vme-modal-content">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-between align-items-center">
          <h3>{{fileUploadStatus.status !== 'completed' ? 'LOGO hochladen' : 'Logo'}}</h3>
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
        <span class="desc">Vorschau</span>
        <div v-if="alert.status" class="text-danger text-center">
          {{alert.msg}}
        </div>
      </div>
      <div class="vme-modal-content-body">
        <div
          v-if="fileUploadStatus.status === 'not_started'"
          @dragover.prevent="onDragOver"
          @dragleave.prevent="onDragLeave"
          @drop.stop.prevent="onDrop"
          class="uploader"
        >
          <input
            class="d-none"
            accept=".jpg,.gif,.png,.jpeg"
            max-file-size="1"
            name="files"
            ref="input"
            type="file"
            @change="onChange($event)"
          />
          <div class="icon">
            <ph-upload-simple :class="{ animated: isDraggedOver }" :size="32" />
          </div>
          <p class="desc">Bild per Drag & Drop hinzufügen.</p>
        </div>
        <div v-if="fileUploadStatus.status === 'progress'" class="uploading">
          <p class="desc">File is uploading...</p>
          <div class="progress">
            <div class="content-progress" :style="`width:${uploadPercent}%`" />
          </div>
        </div>
        <div v-if="fileUploadStatus.status === 'completed'" class="uploading">
          <div class="preview-img">
            <img :src="fileUploadStatus.file" alt="">
          </div>
        </div>
      </div>
      <div  class="vme-modal-content-footer">
        <div v-if="fileUploadStatus.status !== 'not_started'" class="uploading-footer d-flex justify-content-between w-100">
            <div v-if="fileUploadStatus.status === 'progress'" @click="cancelUpload"  class="cancel cursor-pointer">
                <div class="icon">
                    <ph-x-circle :size="24" weight="bold" />
                </div>
                <p>Cancel upload</p>
            </div>
            <div v-if="fileUploadStatus.status === 'completed'" @click="resetUpload"  class="cancel cursor-pointer">
                <div class="icon">
                    <ph-arrow-counter-clockwise :size="24" weight="bold" />
                </div>
                <p>anderes Logo hochladen</p>
            </div>
            <button v-if="fileUploadStatus.status === 'completed'" @click="closeModal(true)" class="btn btn-primary py-2 px-4">OK</button>
        </div>
        <div v-if="fileUploadStatus.status === 'not_started'"
          class="uploader-footer d-flex w-100 align-items-center"
        >
          <button
            class="btn btn-default btn-primary"
            @click="chooseFile"
          >
            Datei auswählen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX, PhUploadSimple, PhXCircle, PhArrowCounterClockwise } from 'phosphor-vue'
export default {
  components: {
    PhX,
    PhXCircle,
    PhUploadSimple,
    PhArrowCounterClockwise
  },
  data () {
    return {

      dragStart: false,
      isDraggedOver: false,
      fileUploadedStasus: false,
      fileUploadStatus: {
        status: 'not_started',
        file: null
      },
      uploadPercent: 1,
      alert: {
        status: false,
        msg: null
      }
    }
  },
  methods: {
    closeModal (isReset = false) {
      this.$emit('closeModal', isReset)
    },
    chooseFile () {
      this.$refs.input.click()
    },
    addLocalFiles (fileList) {
      const files = Array.from(fileList)
      this.controlFile(files[0])
    },
    onDragOver () {
      this.dragStart = true
      this.isDraggedOver = true
    },

    onDragLeave () {
      this.dragStart = false
      this.isDraggedOver = false
    },
    resetUpload () {
      this.fileUploadStatus.status = 'not_started'
      this.uploadPercent = 0
    },
    cancelUpload () {
      this.uploadPercent = 0
      this.fileUploadStatus.status = 'not_started'
      this.$store.dispatch('CANCEL_PENDING_REQUESTS')
    },
    onChange (e) {
      this.alert.status = false
      const fileList = e.target.files
      this.controlFile(fileList[0])
    },

    async  controlFile (file) {
      this.alert.status = false
      const size = file.size
      if (size > 300000) {
        this.alert.status = true
        this.alert.msg = 'Bitte wählen Sie eine Datei im Format JPG, GIF oder PNG. Maximale Größe 300KB.'
        return
      }
      if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
        this.alert.status = true
        this.alert.msg = 'Bitte wählen Sie eine Datei im Format JPG, GIF oder PNG. Maximale Größe 300KB.'
        return
      }
      try {
        this.fileUploadStatus.status = 'progress'
        const formData = new FormData()
        formData.append('logo', file)
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            )
          }
        }
        const response = await this.$api.uploadFirmenLogo(formData, config)
        if (response.data.status === 'success') {
          this.$emit('changeLogo', URL.createObjectURL(file))
          this.fileUploadStatus = {
            status: 'completed',
            file: URL.createObjectURL(file)
          }
        }
      } catch (e) {
        this.alert.status = true
        this.alert.msg = e.message
        this.fileUploadStatus.status = 'progress'
        this.uploadPercent = 0
      }
    },

    onDrop (e) {
      const fileList = e.dataTransfer.files
      if (fileList) {
        this.addLocalFiles(fileList)
      }
      this.onDragLeave()
    }
  }
}
</script>

<style></style>

<template>
  <div class="content m-0">
    <div class="container-fluid p-0">
      <div class="row g-0 h-100">
        <div class="col-4">
          <div class="news-list h-100">
            <perfect-scrollbar>
              <div class="news-list-header">
                <h3>INBOX <span>/ NEWS</span></h3>
              </div>
              <div class="news-list-body h-100">
                <div class="news-group">
                  <span class="title">Today</span>
                </div>
                <div
                  v-for="item in 25"
                  :key="item"
                  class="news"
                  :class="{ active: item === 2 }"
                >
                  <div class="left">
                    <div v-if="item === 1" class="news-status"></div>
                    <div class="news-author">
                      <img src="/imgs/person-info-dummy.png" alt="" />
                    </div>
                  </div>
                  <div class="right">
                    <p class="news-author-name">Silke von Bandel</p>
                    <p class="news-title">
                      Fa. Hapo - EK-Preiserhöhung ab 01..
                    </p>
                    <p class="news-summary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Modi laudantium nihil optio expedita officiis animi!
                      Atque, sit dolorum sint eveniet quasi numquam laborum
                      beatae, minima maxime animi reprehenderit, ea est.
                    </p>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-8 h-100">
          <perfect-scrollbar>
            <div class="news-detail ">
            <div class="news-detail-header">
              <span class="title">Betrifft: </span>
              <span class="desc">EK-Preisänderung</span>
            </div>
            <div class="news-detail-content">
              <div class="news-detail-content-header">
                <h3 class="title">
                  Fa. MWA -Einkaufs-Preiserhöhung zum 01.05.2022
                </h3>
                <div class="author">
                  <img src="/imgs/person-info-dummy.png" alt="" />
                  <p class="name">Silke von Bandel</p>
                  <p class="date">20.01.2022</p>
                </div>
              </div>
            </div>
            <div class="hr"></div>
            <div class="news-detail-text">
                <div class="text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit, rem assumenda quia cupiditate quasi necessitatibus sint
                    itaque? Ab quas ratione commodi impedit nulla, officia atque
                    voluptatibus dolore a rerum placeat.
                  </p>
                </div>
                <div class="file">
                  <div class="preview">
                    <img src="/imgs/news-file.png" alt="">
                  </div>
                  <button class="btn btn-primary">
                    <ph-download :size="18" class="mr-2" weight="bold" /> <span class="ml-2">Download</span>
                  </button>
                </div>
              </div>
          </div>
          </perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhDownload } from 'phosphor-vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    PhDownload
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB'])
  },
  created () {
    const data = [
      {
        title: 'LIeferant'
      },
      {
        title: ' / VERKAUF'
      }
    ]
    this.SET_BREADCRUMB(data)
  }
}
</script>

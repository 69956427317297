<template>
  <div class="vme-modal">
    <div class="vme-modal-content" style="min-width: 620px">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mt-2">
            ACHTUNG!
          </h3>
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
      </div>
      <div class="vme-modal-content-body mt-4">
          <p class="para">Preislisten für Kalkulationen zwischen 180 und 400% sind sofort abrufbar. Werte unter 10% sind nicht möglich.</p><br/>
          <p class="para bold-p">Davon abweichende Kalkulationen  können bis zu {{ msg.num }} Stunden in Anspruch nehmen.</p><br/>
          <p class="para">{{ msg.text }}</p><br/>
          <div class="sie">Möchten Sie trotzdem fortfahren?</div>
          <div class="d-flex mt-4 w-100 mt-2">
              <button class="btn btn-primary btn-default" @click="updated">
                Ja, Fortfahren
              </button>
              <span @click.prevent="closeModal" class="abb px-4 py-2 me-3">Abbrechen</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue'
export default {
  props: ['msg'],
  components: {
    PhX
  },
  methods: {
    updated () {
      this.$emit('update')
    },
    closeModal () {
      this.$emit('closed')
    }
  }
}
</script>
<style scoped>
.para {
  font-size:1.3em;
  max-width: 530px;
}

.abb {
  text-decoration:underline;
  cursor: pointer;
}

.bold-p{
  font-weight: bold;
}

.sie {
  color:#ee7f00;
}
</style>

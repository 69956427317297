<template>
      <div class="vme-modal">
      <div class="vme-modal-content">
        <div class="vme-modal-content-body">
          <h3 class="text-center text-success">Erfolgreich</h3>
          <div class="d-flex justify-content-center mt-4 mb-0">
            <button  @click.prevent="closeModal" class="btn btn-primary px-4 py-2">schließen</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    closeModal () {
      this.$emit('closed')
    }
  }
}
</script>

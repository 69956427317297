<template>
   <div class="vme-modal">
    <div class="vme-modal-content" style="min-width: 500px">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-between">
          <h3 class="mt-2">
            SPCZ löschen
          </h3>
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
      </div>
      <div class="vme-modal-content-body mt-4">
          <span class="text-center">SPCZ Datei wirklich löschen?</span >
          <div class="d-flex mt-4 w-100 mt-2">
              <button class="btn btn-primary px-4 py-2" @click="deleted">
              <loading v-if="loading" :size="'sm'" style="width: 60px;height: 20px;"></loading>
                <span v-else>Ja, löschen</span>
              </button>
              <span @click.prevent="closeModal" class="px-4 py-2 me-3" style="text-decoration:underline;cursor: pointer;">Abbrechen</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue'
import Loading from '../common/Loading.vue'
export default {
  props: ['loading'],
  components: {
    PhX,
    Loading
  },
  methods: {
    closeModal () {
      this.$emit('closed')
    },
    deleted () {
      this.$emit('deleted')
    }
  }
}
</script>

<template>
  <div class="card w-100">
      <div class="card-body h-100">
         <div class="div w-100 h-100 d-flex flex-column align-items-center justify-content-center">
             <h3 class="text-color-primary text-center">keine Berechtigung</h3>
             <p class="text-color-gary text-center">
                 Sie haben leider keine Berechtigung. Bitte wenden Sie sich an Ihren Admin.
             </p>
         </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>

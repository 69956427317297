<template>
   <div class="vme-modal">
    <div class="spcz-modal-content edit-modal">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-end align-items-center">
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
      </div>
      <error-screen
        @goBack="resetAlert"
        :msg="alert.msg"
        style="margin-top: 8em;"
        v-if="alert.status"
      ></error-screen>
      <div class="vme-modal-content-body">
        <div v-if="!alert.status" class="spcz-container">
          <div class="card">
            <div class="card-body">
              <div class="title item-left w-100 d-flex flex-column">
                SPCZ Daten ändern
              </div>
              <div class="d-flex mt-4">
                <div class="flex-grow-1">
                  <div class="item w-100">
                    <div class="item-left">Status</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <select
                          placeholder="Status"
                          v-model="form.status"
                          :disabled="!models.selected"
                          name=""
                          id=""
                          class="form-control bordered"
                        >
                          <option value="" selected hidden>auswählen</option>
                          <option value="neuanlage">NEU</option>
                          <option value="preisverlängerung">PVERL</option>
                          <option value="preisänderung">PAEND</option>
                          <option value="div. änderungen">UPDATE</option>
                          <option value="ergänzung">ERGAENZUNG</option>
                          <option value="herst. auslauf">AUSLAUF</option>
                          <option value="Typenänderung">TAEND</option>
                          <option value="maßänderung">MASSAEND</option>
                          <option value="konditionsänderung">KONDAEND</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="item w-100">
                    <div class="item-left">Gültig ab</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <date-picker
                          placeholder="dd.mm.yyyy"
                          :disabled="!models.selected"
                          format="DD.MM.YYYY"
                          :formatter="myFormat"
                          v-model="form.validFrom"
                          valueType="format"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="item w-100">
                    <div class="item-left">Gültig bis</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <date-picker
                          placeholder="dd.mm.yyyy"
                          :disabled="!models.selected"
                          format="DD.MM.YYYY"
                          :formatter="myFormat"
                          v-model="form.validTill"
                          valueType="format"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="item w-100">
                    <div class="item-left">Teuerungszuschlag</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <select v-model="form.tz"
                          :disabled="!models.selected" class="form-control bordered">
                            <option value="" selected hidden>auswählen</option>
                            <option value="ja">ja</option>
                            <option value="nein">nein</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="item w-100">
                    <div class="item-left">Zusatznr</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <input v-model="form.zusatznr" class="form-control bordered" disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="item w-100">
                    <div class="item-left">Zusatz</div>
                    <div class="item-right">
                      <div class="form-group mb-0">
                        <input v-model="form.zusatz" class="form-control bordered" @input="onZusatz" style="padding-left:10px" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-0 flex-column align-items-start flex-grow-1">
                  <div class="item-left">Modell Info</div>
                  <div class="item-right w-100 h-100">
                    <div class="form-group w-100 mt-3">
                      <textarea
                        :disabled="!models.selected"
                        v-model="form.modelInfo"
                        cols="40"
                        rows="10"
                        class="form-control text-area"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <button
                @click="checkOverlay"
                :disabled="!models.selected"
                class="btn btn-primary btn-md btn-default mt-3"
              >
                <loading v-if="loading" :size="'sm'" style="width: 100%;height: 20px;" />
                <span v-else>Speichern</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spcz-confirm-modal @closed="openConfirmModal=false" @updated="updateForm" v-if="openConfirmModal" :loading="loading" />
  </div>
</template>

<script>
import ErrorScreen from '@/components/common/ErrorScreen.vue'
import { PhX } from 'phosphor-vue'
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/common/Loading.vue'
import SpczConfirmModal from './SpczConfirmModal.vue'
export default {
  components: {
    PhX,
    ErrorScreen,
    SpczConfirmModal,
    Loading,
    DatePicker
  },
  props: ['item', 'models', 'suppliers'],
  data () {
    return {
      loading: false,
      openConfirmModal: false,
      alert: {
        status: false,
        msg: null
      },
      form: {
        tz: this.item.tz ? 'ja' : 'nein',
        supplierId: this.item.supplierId,
        modelName: this.item.modelName,
        status: this.item.status,
        modelId: this.item.modelId,
        modelInfo: this.item.modelInfo,
        zusatznr: this.item.zusatznr,
        zusatz: this.item.zusatz,
        validFrom: this.item.validFrom,
        validTill: this.item.validTill
      },
      myFormat: {
        // Date to String
        stringify: date => {
          return date ? this.$moment(date).format('DD.MM.YYYY') : null
        },
        // String to Date
        parse: value => {
          return value ? this.$moment(value, 'DD/MM/YYYY').toDate() : null
        }
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closed')
    },
    deleted () {
      this.$emit('deleted')
    },
    resetAlert (par) {
      if (!par) {
        this.alert.status = false
        this.alert.msg = null
      }
    },
    getMeta (item, tz) {
      return {
        supplierId: item.supplierId,
        modelId: item.modelId,
        tz: tz,
        zusatz: item.zusatz,
        zusatznr: item.zusatznr,
        modelName: item.modelName,
        status: item.status,
        modelInfo: item.modelInfo,
        validFrom: item.validFrom,
        validTill: item.validTill
      }
    },
    onZusatz (e) {
      const txt = e.target.value
      if (txt.length > 15) {
        this.form.zusatz = txt.slice(0, 15)
      }
    },
    async checkOverlay () {
      if (!this.form.status) {
        this.alert.status = true
        this.alert.msg = 'Status ist erforderlich.'
        return
      }
      if (!this.form.tz) {
        this.alert.status = true
        this.alert.msg = 'Teuerungszuschlag ist erforderlich.'
        return
      }
      if (!this.form.validFrom) {
        this.alert.status = true
        this.alert.msg = 'Gültig ab ist erforderlich..'
        return
      }
      if (!this.form.validTill) {
        this.alert.status = true
        this.alert.msg = 'Gültig bis ist erforderlich..'
        return
      }
      try {
        this.loading = true
        const response = await this.$api.checkOverlap(
          this.getMeta(this.item, this.item.tz),
          this.getMeta(this.form, this.form.tz === 'ja')
        )
        if (response.data.status === 'success') {
          if (response.data.data.overlap) {
            this.openConfirmModal = true
          } else {
            this.updateForm()
          }
        }
      } catch (e) {
        this.alert.status = true
        this.alert.msg = e.response.data.message
      } finally {
        this.loading = false
      }
    },
    async updateForm () {
      try {
        this.$parent.$el.scrollTop = 0
        this.loading = true
        this.form.supplierId = this.suppliers.selected.supplierId
        this.form.modelId = this.models.selected.modelId
        const response = await this.$api.updateFile(
          this.getMeta(this.item, this.item.tz),
          this.getMeta(this.form, this.form.tz === 'ja')
        )
        if (response.status === 200) {
          this.loading = false
          this.$emit('success', this.form)
        }
      } catch (e) {
        this.alert.status = true
        this.loading = false
        this.alert.msg = e.response.data.message === 'body.validTill must be greater than body.validFrom' ? 'Gültig ab Datum muss vor dem Gültig bis Datum liegen' : 'System Error'
      } finally {
        this.loading = false
        this.openConfirmModal = false
      }
    }
  }
}
</script>
<style>
.edit-modal {
  top: 10%;
  height: calc(100% - 150px);
  width:60%;
  right:20%;
  left:20%
}
@media only screen and (max-width: 1024px) {
  .edit-modal {
    top: 10%;
    left:13%;
  }
}
</style>

<template>
   <div class="vme-modal">
    <div class="vme-modal-content" style="min-width: 600px">
      <div class="vme-modal-content-header">
        <div class="d-flex justify-content-between">
          <h3 class="mt-2">
            ACHTUNG!
          </h3>
          <a @click.prevent="closeModal" class="close-icon" href="#">
            <ph-x :size="24" />
          </a>
        </div>
      </div>
      <div class="vme-modal-content-body mt-4">
          <p style="font-size:1.3em;max-width: 500px;">Es existiert schon eine Liste mit überlappendem Gültigkeitszeitraum.</p><br/>
          <span style="color:#ee7f00">Neue Liste trotzdem hochladen?</span>
          <div class="d-flex mt-4 w-100 mt-2">
              <button class="btn btn-primary btn-default" @click="updated">
              <loading v-if="loading" :size="'sm'" style="width:100%;height:10px;"></loading>
                <span v-else>Ja, Speichern</span>
              </button>
              <span @click.prevent="closeModal" class="px-4 py-2 me-3" style="text-decoration:underline;cursor: pointer;">Abbrechen</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue'
import Loading from '../common/Loading.vue'
export default {
  props: ['loading'],
  components: {
    PhX,
    Loading
  },
  methods: {
    closeModal () {
      this.$emit('closed')
    },
    updated () {
      this.$emit('updated')
    }
  }
}
</script>

<template>
  <div  class="error-screen">
      <div class="icon">
          <ph-warning-circle :size="32" />
      </div>
      <h3 class="message">
          {{msg}}
      </h3>
      <button @click="$emit('goBack')" class="btn btn-primary px-4 py-2 mt-3">
          Zurück
      </button>
  </div>
</template>

<script>
import { PhWarningCircle } from 'phosphor-vue'
export default {
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  components: {
    PhWarningCircle
  }
}
</script>

<template>
  <div class="h-100">
    <div v-if="loading" class="h-100">
      <loading />
    </div>
    <div v-if="!loading" class="vme-user-list">
      <div
        class="vme-user-list-header d-flex justfiy-content-between align-items-center"
      >
        <div class="left w-50">
          <span>{{ totalUSer }} Benutzer gefunden</span>
        </div>
        <div class="right w-50 d-flex justify-content-end">
          <div class="form-group position-relative" style="width: 350px">
            <div class="form-control-icon">
              <ph-magnifying-glass weight="bold" :size="22" />
            </div>
            <input
              @keypress.enter="searchUser"
              v-model.trim="search"
              type="text"
              class="form-control"
              placeholder="Benutzer suchen"
            />
            <div
            @click="resetSearch"
            v-if="searched"
              style="
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
              "
              class="icon cursor-pointer"
            >
              <ph-x weight="bold" :size="22" />
            </div>
          </div>
        </div>
      </div>
      <div class="vme-user-list-body">
        <loading v-if="loadingSearch" />
        <template v-for="(user, i) in filteredList">
          <div v-if="!loadingSearch" :key="i" class="item">
            <div
              v-if="user.loading"
              class="w-100 d-flex justify-content-center"
            >
              <loading />
            </div>
            <div v-if="!user.loading" class="left">
              <div class="icon">
                <ph-user :size="24" />
              </div>
              <div class="user-info">
                <h3 v-html="getName(user.displayName)"></h3>
                 <span v-if="user.email">{{user.email}}</span>
              </div>
            </div>
            <div v-if="!user.loading" class="right">
              <p>Berechtigungen:</p>
              <div class="permisson-list d-flex">
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      @change="changeRole(user, 'isPurchaserUser', $event)"
                      :checked="user.roles.isPurchaserUser"
                      type="checkbox"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px">Einkauf</span>
                  </label>
                </div>
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      :checked="user.roles.isSellerUser"
                      @change="changeRole(user, 'isSellerUser', $event)"
                      type="checkbox"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px">Verkauf</span>
                  </label>
                </div>
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      :checked="user.roles.isCalculationUser"
                      @change="changeRole(user, 'isCalculationUser', $event)"
                      type="checkbox"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px"
                      >Kalkulation</span
                    >
                  </label>
                </div>
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      type="checkbox"
                      :checked="user.roles.isNewsUser"
                      @change="changeRole(user, 'isNewsUser', $event)"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px">News</span>
                  </label>
                </div>
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      type="checkbox"
                      :checked="user.roles.isUserManagementUser"
                      @change="changeRole(user, 'isUserManagementUser', $event)"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px"
                      >Benutzerverwaltung</span
                    >
                  </label>
                </div>
                <div class="permisson-list-item me-4">
                  <label class="b-contain d-flex">
                    <input
                      type="checkbox"
                      :checked="user.roles.isProductInfoUser"
                      @change="changeRole(user, 'isProductInfoUser', $event)"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px"
                      >Produkt Info</span
                    >
                  </label>
                </div>
                <div class="permisson-list-item">
                  <label class="b-contain d-flex">
                    <input
                      type="checkbox"
                      :checked="user.roles.isSPCZUser"
                      @change="changeRole(user, 'isSPCZUser', $event)"
                    />
                    <div
                      class="b-input rounded-0"
                      style="left: 0 !important"
                    ></div>
                    <span class="ms-2" style="font-size: 14px"
                      >SPCZ</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="!loadingMore && !loading && !loadingSearch"
          class="d-flex justify-content-center"
        >
          <button
            v-if="isLoad"
            @click="getMoreUsers"
            class="btn btn-primary my-5 btn-default"
          >
            Mehr Benutzer anzeigen
          </button>
        </div>
        <div v-if="noMoreData && !loadingMore" class="row mt-2">
          <div class="col-12">
            <h3 class="justify-content-center d-flex w-100 mb-5">
              Keine weiteren Benutzer
            </h3>
          </div>
        </div>
        <div v-if="loadingMore" class="row">
          <div class="col-12">
            <loading :size="'sm'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '../common/Loading.vue'
import { PhMagnifyingGlass, PhUser, PhX } from 'phosphor-vue'
export default {
  components: {
    PhMagnifyingGlass,
    PhX,
    PhUser,
    loading
  },
  data () {
    return {
      searched: false,
      loadingSearch: false,
      noMoreData: false,
      loadingMore: false,
      loading: true,
      loadingItems: [],
      users: [],
      isLoad: true,
      totalUSer: 0,
      search: '',
      nextSkipToken: ''
    }
  },
  methods: {
    getMoreUsers () {
      if (this.users.length === this.totalUSer) {
        return
      }
      this.getUserList(this.nextSkipToken)
    },
    getLoadingItems (email) {
      const findIndex = this.loadingItems.findIndex(
        (item) => item.email === email
      )
      return findIndex
    },
    changeRole (user, type, $event) {
      const findUserIndex = this.users.findIndex(
        (item) => item.email === user.email
      )
      this.users[findUserIndex].loading = !this.users[findUserIndex].loading
      const val = $event.target.checked
      this.updateUserRoles(user, type, val)
    },
    async updateUserRoles (user, role, val) {
      try {
        const newRole = {
          email: user.email,
          [role]: val,
          merchantId: user.merchantId
        }
        const par = {
          users: [newRole]
        }
        const updateUser = await this.$api.updateList(par)
        if (updateUser.data.data) {
          const findUserIndex = this.users.findIndex(
            (item) => item.email === user.email
          )
          this.users[findUserIndex].roles[role] = val
        }
      } catch (e) {
        console.error(e)
      } finally {
        const findUserIndex = this.users.findIndex(
          (item) => item.email === user.email
        )
        this.users[findUserIndex].loading = false
      }
    },
    resetSearch () {
      this.search = ''
      this.searched = false
      this.loading = true
      this.getUserList()
    },
    async searchUser () {
      try {
        this.searched = true
        this.loadingSearch = true
        this.users = []
        const response = await this.$api.searchUser(this.search)
        this.nextSkipToken = response.data.data.nextSkipToken
        if (response.data.data.total <= 10) {
          this.isLoad = false
        }
        if (response.data.data.nextSkipToken.length === 0) {
          this.noMoreData = true
        }
        response.data.data.items.map((item) => {
          this.users.push({
            ...item,
            loading: false
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingSearch = false
      }
    },
    getName (name) {
      if (window.innerWidth === 1024) {
        const fullname = name.split(' ')
        return fullname.length === 2 ? `${fullname[0]} <br> ${fullname[1]}` : name
      }
      return name
    },
    async getUserList (token = null) {
      if (this.totalUSer === null) {
        return
      }
      try {
        if (!token) {
          this.users = []
        }
        if (token) {
          this.loadingMore = true
        }
        const response = await this.$api.getUserList(token)
        this.totalUSer = response.data.data.total || this.totalUSer
        if (response.data.data.nextSkipToken.length === 0) {
          this.noMoreData = true
        }
        if (this.totalUSer <= 10) {
          this.isLoad = false
        }
        this.nextSkipToken = response.data.data.nextSkipToken
        response.data.data.items.map((item) => {
          this.users.push({
            ...item,
            loading: false
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingMore = false
        this.loading = false
      }
    },
    myEventHandler (e) {
      window.scrollTo(0, 0)
    }
  },
  computed: {
    filteredList () {
      return this.users
    }
  },
  async created () {
    window.addEventListener('resize', this.myEventHandler)
    this.getUserList()
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  }
}
</script>

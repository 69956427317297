<template>
  <div class="w-100 d-flex flex-column align-items-start">
    <div class="w-100 d-flex">
      <div class="calc-select-section position-relative">
        <loading
          v-if="loading"
          class="mb-5"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%);
          "
        />
        <div v-if="!loading" class="left">
          <div class="form-group mb-4">
            <label for="" class="text-color-primary">Lieferant</label>
            <multi-select
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="isLoading"
              :searchable="true"
              :showLabels="false"
              open-direction="bottom"
              v-model="selectedSupplier"
              :options="suppliers.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectOptionSupplier"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchSupplier"
            >
            <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{ props.option.supplierId }} {{
                  props.option.fullName
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span>{{ props.option.supplierId }} {{  props.option.fullName }}</span>
                </div>
              </template>
            </multi-select>
          </div>
          <div class="form-group">
            <label for="" class="text-color-primary">Modell</label>
            <multi-select
              @close="resetPointer"
              ref="modelsSelect"
              :disabled="!selectedSupplier"
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="isLoading"
              :searchable="true"
              :showLabels="false"
              v-model="selectedModel"
              :options="models.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectOptionModels"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchModel"
            >
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option">{{ props.option.modelId }} {{
                  props.option.name
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span>{{ props.option.modelId }} {{ props.option.name }}</span>
                </div>
              </template>
              <template
                v-if="modelsPage !== paginator.totalPages && models.options.length >= 10"
                slot="afterList"
              >
                <a class="load-more-btn" @click="loadMoreModels" href="#"
                  >Mehr Laden</a
                >
              </template>
            </multi-select>
          </div>
        </div>
        <div v-if="!loading" class="right w-100">
          <div class="fimen-setting">
            <div class="firmen-setting-section mt-4">
              <div
                v-for="(item, index) in getCalcData"
                :key="index"
                class="firmen-setting-section-item"
              >
                <div class="firmen-setting-section-item-left">
                  <h3>Kalkulation {{ item.id }}</h3>
                </div>
                <div class="right d-flex align-items-center">
                  <div class="form-group me-3">
                    <input
                      :disabled="isDisableInput"
                      type="text"
                      placeholder="Set Name"
                      v-model.trim="item.name"
                      @change="getValidity"
                      class="form-control bordered"
                    />
                  </div>
                  <div
                    class="firmen-input form-group"
                    :class="{ 'pointer-none': isDisableInput }"
                  >
                    <input
                      type="number"
                      placeholder="range"
                      v-model.trim="item.val"
                      @change="onRange(item)"
                      class="form-control bordered"
                    />
                  </div>
                  <a
                    :class="{ 'pointer-none': isDisableInput }"
                    @click.prevent="clearVal(item.id)"
                    class="ml-2 text-color-primary"
                    href="#"
                  >
                    <ph-x :size="18" weight="bold" />
                  </a>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-end my-3">
                <div v-if="alert" class="text-danger" style="margin-right: 2em">
                  <p
                    v-for="(error, index) in errorCalcs"
                    class="mb-0"
                    :key="index"
                  >
                    {{ error.msg }}
                  </p>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-end mb-2">
                <button
                  :disabled="isDisableInput || alert"
                  @click="updateSettings"
                  style="min-width: 120px"
                  class="btn btn-primary btn-md btn-default"
                >
                  <loading v-if="onloading" :size="'sm'" style="width: 100%;height: 20px;"/>
                  <span v-else>Speichern</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="calc-list w-100">
      <div class="calc-list-header w-100 d-flex justify-content-end mb-3">
        <div style="width: 160px" class="page-sorting-items">
          <span class="me-3 text-xs text-color-65-gray">Filter</span>
          <select
          @change="changeFilter"
          v-model="filter"
            class="form-control sorting-select font-bold"
          >
            <option selected value="all">All Type</option>
            <option value="supplier">Lieferant</option>
            <option value="model">Modell</option>
          </select>
        </div>
        <div  class="search-bar">
          <div class="form-group position-relative">
            <div class="form-control-icon">
              <ph-magnifying-glass weight="bold" :size="22" />
            </div>
            <input @keyup.enter="doSearch" type="text" v-model="searchKeyw" class="form-control" placeholder="Search in calculations" />
          </div>
        </div>
      </div>
      <div
        v-if="loadingCalcList"
        class="position-relative w-100"
        style="height: 250px"
      >
        <loading
          class="mb-5"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%);
          "
        />
      </div>
      <div v-if="calcList.length > 0 && !loadingCalcList" class="calc-list-body w-100">
        <div v-for="(item, index) in calcList" :key="index">
          <div class="w-100">
            <div class="calc-list-item">
              <div class="calc-list-item-header">
                <div class="left">
                  <span>Type:</span>
                  {{ item.type === "model" ? "Modell" : "Lieferant" }}
                </div>
                <div class="right">
                  <div class="icon" @click="openMs(item)">
                    <ph-currency-eur weight="bold" />
                  </div>
                  <div class="icon" @click="calcEdit(item)">
                    <ph-note-pencil weight="bold" />
                  </div>
                  <div class="icon">
                    <ph-trash @click="deleteCalc(item)" weight="bold" />
                  </div>
                </div>
              </div>
              <div class="calc-list-item-body">
                <div class="left">
                  <div class="supplier">
                    <span class="item-title"> Lieferant </span>
                    <span class="item-desc no-space" :class="{'ellipse' : item.type === 'model'}">
                      {{ item.supplierId }} - {{ item.supplierName }}
                    </span>
                  </div>
                  <div class="model">
                    <div v-if="item.type === 'model'" class="d-flex flex-column">
                      <span class="item-title"> Modell </span>
                      <span class="item-desc ellipse">
                        {{ item.modelId }} - {{ item.modelName }}
                      </span>
                    </div>
                  </div>
                  <div class="item" style="margin-left: 15px;">
                    <span class="item-title">Erstelldatum</span>
                    <span class="item-desc">{{ item.createdAt ? $moment(item.createdAt).format('DD.MM.YYYY') : '' }}</span>
                  </div>
                  <div class="item">
                    <span class="item-title">Zuletzt bearbeitet</span>
                    <span class="item-desc">
                      {{ item.updatedAt ? $moment(item.updatedAt).format('DD.MM.YYYY') : '' }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center" style="height: 30px" v-if="item.updatedBy">
                    <div class="user-icon">
                      <ph-user class="" :size="18" />
                    </div>
                    <span class="username"> {{ item.updatedBy ? item.updatedBy.name : '' }}</span>
                  </div>
                </div>
                <div class="right">
                  <div class="item">
                    <span class="item-title">
                      {{ getCalcListName(item.settings.field1) }}
                    </span>
                    <span class="item-desc text-right">
                      {{ getCalcValue(item.settings.field1) }}
                    </span>
                  </div>
                  <div class="item">
                    <span class="item-title">
                      {{ getCalcListName(item.settings.field2) }}
                    </span>
                    <span class="item-desc text-right">
                      {{ getCalcValue(item.settings.field2) }}
                    </span>
                  </div>
                  <div class="item">
                    <span class="item-title">
                      {{ getCalcListName(item.settings.field3) }}
                    </span>
                    <span class="item-desc text-right">
                      {{ getCalcValue(item.settings.field3) }}
                    </span>
                  </div>
                  <div class="item">
                    <span class="item-title">
                      {{ getCalcListName(item.settings.field4) }}
                    </span>
                    <span class="item-desc text-right">
                      {{ getCalcValue(item.settings.field4) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-end">
        <paginate
          v-if="calcPagination"
          v-model="page"
          :page-count="calcPagination.totalPages"
          :page-range="3"
          :margin-pages="1"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="getAllCalculations"
          :container-class="'pagination'"
        >
          <span slot="prevContent"><ph-caret-left /></span>
          <span slot="nextContent"><ph-caret-right /></span>
        </paginate>
      </div>
    </div>
    <confirm-percent-range v-if="showConfirmRange" @closed="updateRange(true)" @update="updateRange" :msg="disMsg" />
    <delete-calc-confrim-modal @closed="closedDeleteModal" :item="selectDeleteItem" @deleted="deleteCalcItem" v-if="openDeleteModal" />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import Loading from '@/components/common/Loading.vue'
import Paginate from 'vuejs-paginate'
import {
  PhMagnifyingGlass,
  PhX,
  PhNotePencil,
  PhTrash,
  PhCaretRight,
  PhCaretLeft,
  PhCurrencyEur,
  PhUser
} from 'phosphor-vue'
import ConfirmPercentRange from './ConfirmPercentRange.vue'
import DeleteCalcConfrimModal from './DeleteCalcConfrimModal.vue'
export default {
  components: {
    Loading,
    MultiSelect,
    PhMagnifyingGlass,
    Paginate,
    PhCaretRight,
    PhX,
    PhCaretLeft,
    PhNotePencil,
    PhTrash,
    PhCurrencyEur,
    PhUser,
    ConfirmPercentRange,
    DeleteCalcConfrimModal
  },
  data () {
    return {
      selectDeleteItem: null,
      openDeleteModal: false,
      suppliers: {
        options: [],
        selected: null
      },
      models: {
        options: [],
        selected: null
      },
      isLoading: false,
      searchKeyw: '',
      loadingCalcList: true,
      isEdit: false,
      filter: 'all',
      ModalType: '',
      selectedModel: null,
      selectedSupplier: null,
      selectedSupplierId: '',
      isSuccess: false,
      selectEditCalc: null,
      clearVals: [],
      logo: null,
      openModal: false,
      showConfirmRange: false,
      loading: true,
      onloading: false,
      modelsPage: 1,
      selectedCalc: {},
      calcPagination: null,
      alert: true,
      errorCalcs: [],
      stepValues: [],
      calcList: [],
      calcs: [],
      page: 1,
      paginator: {},
      disMsg: {
        num: 24,
        text: 'Sie werden per E-Mail informiert, wenn diese bereit stehen.'
      }
    }
  },
  computed: {
    getCalcData () {
      return this.calcs
    },
    isDisableInput () {
      if (this.selectedModel || this.selectedSupplier || this.selectEditCalc) {
        return false
      }
      return true
    }
  },
  methods: {
    closedDeleteModal () {
      this.openDeleteModal = false
      this.selectDeleteItem = null
    },
    searchModel (query) {
      this.modelsPage = 1
      this.modelSearchKeyw = query
      this.getModelsBySupplierId(false, query)
    },
    loadMoreModels () {
      this.modelsPage++
      this.getModelsBySupplierId(true, this.modelSearchKeyw)
    },
    async getModelsBySupplierId (load = false, query = '') {
      try {
        let response = null
        if (query.length === 0) {
          response = await this.$api.getModelList({
            limit: 10,
            page: this.modelsPage,
            id: this.selectedSupplierId,
            filter: JSON.stringify({ modelStatus: 'all' })
          })
        }
        if (query.length > 0) {
          response = await this.$api.getModelList({
            limit: 10,
            searchKeyw: query,
            page: this.modelsPage,
            id: this.selectedSupplierId,
            filter: JSON.stringify({ modelStatus: 'all' })
          })
        }

        this.paginator = response.data.data.paginator
        if (!load) {
          this.models.options = response.data.data.items
        }
        if (load) {
          response.data.data.items.map((item) => {
            this.models.options.push({
              ...item
            })
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    resetPointer () {
      this.$refs.modelsSelect.pointer = 0
      if (this.$refs.modelsSelect.$refs.list) {
        this.$refs.modelsSelect.$refs.list.scrollTop = 0
      }
    },
    async searchSupplier (query) {
      try {
        if (query.length < 3) {
          this.isLoading = false
          return
        }
        this.isLoading = true
        const data = {
          limit: 50,
          page: 1,
          keyw: query
        }
        const response = await this.$api.searchSupplier(data)
        this.suppliers.options = []
        response.data.data.items.map((item) => {
          if (item.fullName) {
            this.suppliers.options.push({
              ...item
            })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async selectOptionSupplier (selectedOption) {
      this.selectedModel = null
      this.selectedSupplier = selectedOption
      this.selectedSupplierId = selectedOption.supplierId
      this.isEdit = false
      this.selectEditCalc = null
      this.onloading = true
      this.setCalcDefault()
      const resp = await this.$api.getCalulations(selectedOption.supplierId, '')
      if (resp.data.status === 'success') {
        if (resp.data.data) {
          let id = 1
          this.calcs = []
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(resp.data.data.settings)) {
            this.calcs.push({
              id: id++,
              name: value ? value.name || '' : '',
              val: value ? this.getStepLabel(value.stepValue) : ''
            })
          }
        }
      }
      this.onloading = false
      this.getModelsBySupplierId()
    },
    async selectOptionModels (selectedOption) {
      this.selectedModel = selectedOption
      this.isEdit = false
      this.selectEditCalc = null
      this.onloading = true
      this.setCalcDefault()
      const resp = await this.$api.getCalulations(this.selectedSupplier.supplierId, selectedOption.modelId)
      if (resp.data.status === 'success') {
        if (resp.data.data) {
          let id = 1
          this.calcs = []
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(resp.data.data.settings)) {
            this.calcs.push({
              id: id++,
              name: value ? value.name || '' : '',
              val: value ? this.getStepLabel(value.stepValue) : ''
            })
          }
        }
      }
      this.onloading = false
    },
    changeFilter () {
      this.searchKeyw = ''
      this.page = 1
      this.getAllCalculations()
    },
    getCalcValue (par) {
      if (!par) {
        return '-'
      }
      if (!par.stepValue) {
        return '-'
      }
      if (par.stepValue) {
        const x = this.getStepLabel(par.stepValue)
        if (x === 180 && par.name) {
          return x + '%'
        }
        if (x === 180 && !par.name) {
          return 'None'
        }
        return x + '%'
      }
    },
    getCalcListName (par) {
      if (!par) {
        return '-'
      }
      if (!par.name) {
        return '-'
      }
      return par.name
    },
    isHaveClear (index) {
      const isHave = this.clearVals.findIndex((item) => item === index)
      if (isHave !== -1) {
        return true
      }
      return false
    },
    changeLogoAfterUpload (val) {
      this.logo = val
    },
    clearVal (id) {
      const idx = this.calcs.findIndex((item) => item.id === id)
      this.calcs[idx].name = ''
      this.calcs[idx].val = ''
      this.getValidity()
      const findIndex = this.clearVals.findIndex((item) => item === idx)
      if (findIndex === -1) {
        this.clearVals.push(idx)
      }
    },
    calcEdit (item) {
      this.selectedModel = null
      this.selectedSupplier = null
      this.alert = true
      this.selectedSupplierId = ''
      this.suppliers.options = []
      if (item.type) {
        this.selectedSupplierId = item.supplierId
        if (item.type === 'model') {
          this.selectedModel = {
            modelId: item.modelId,
            supplierId: item.supplierId,
            name: item.modelName
          }
        }
        this.selectedSupplier = {
          supplierId: item.supplierId,
          fullName: item.supplierName
        }
        this.getModelsBySupplierId()
      }
      this.isEdit = true
      this.$parent.$el.scrollTop = 0
      this.selectEditCalc = item
      let id = 1
      this.calcs = []
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(item.settings)) {
        this.calcs.push({
          id: id++,
          name: value ? value.name || '' : '',
          val: value ? this.getStepLabel(value.stepValue) : ''
        })
      }
    },
    async doSearch () {
      this.page = 1
      this.getAllCalculations(true)
    },
    setCalcDefault () {
      for (let i = 0; i < 4; i++) {
        this.calcs[i] = {
          id: i + 1,
          name: null,
          val: null
        }
      }
    },
    async deleteCalcItem (item) {
      this.openDeleteModal = false
      this.setCalcDefault()
      try {
        let tempData = null
        let settings = {}
        for (const index in this.calcs) {
          tempData = {
            ['field' + `${this.calcs[index].id}`]: null
          }
          settings = Object.assign(settings, tempData)
        }
        const data = {
          settings
        }
        if (item.type === 'supplier') {
          data.type = 'supplier'
          data.supplierId = item.supplierId
        }
        if (item.type === 'model') {
          data.type = 'model'
          data.supplierId = item.supplierId
          data.modelId = item.modelId
        }
        const response = await this.$api.deleteSettings(data)
        if (response.data.status === 'success') {
          this.selectDeleteItem = null
          this.getAllCalculations()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async deleteCalc (item) {
      this.selectDeleteItem = item
      this.openDeleteModal = true
    },
    openMs (item) {
      if (item.type === 'model') {
        this.$router.push(`/seller/model/${item.modelId}?supplierId=${item.supplierId}`)
      } else {
        this.$router.push(`/seller/models?seller=${item.supplierName}&id=${item.supplierId}`)
      }
    },
    async getAllCalculations (search = false) {
      try {
        this.loadingCalcList = true
        const data = {
          type: this.filter,
          page: this.page
        }
        if (search) {
          data.keyw = this.searchKeyw
        }
        this.$parent.$el.scrollTop = 0
        const response = await this.$api.getCalcList(data)
        this.calcList = response.data.data.items
        this.calcPagination = response.data.data.paginator
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingCalcList = false
      }
    },
    async updateSettings () {
      if (this.checkStepValues() || this.checkEmpty()) {
        return
      }
      try {
        this.loading = true
        let settings = {}
        let tempData = {}
        for (const index in this.calcs) {
          tempData = {
            ['field' + `${this.calcs[index].id}`]: {
              name: this.calcs[index].name,
              stepValue: `step_${this.calcs[index].val}`
            }
          }
          settings = Object.assign(settings, tempData)
        }
        for (const property in settings) {
          if (!settings[property].name) {
            settings[property] = null
          }
        }
        let data = {
          settings
        }
        if (this.selectedSupplier && !this.selectedModel) {
          data.type = 'supplier'
          data.supplierId = this.selectedSupplierId
        }
        if (this.selectedSupplier && this.selectedModel) {
          data.type = 'model'
          data.supplierId = this.selectedSupplierId
          data.modelId = this.selectedModel.modelId
        }
        if (this.selectEditCalc) {
          if (this.selectEditCalc.type === 'model') {
            data = {
              ...data,
              type: 'model',
              supplierId: this.selectEditCalc.supplierId,
              modelId: this.selectEditCalc.modelId
            }
          }
          if (this.selectEditCalc.type === 'supplier') {
            data = {
              ...data,
              type: 'supplier',
              supplierId: this.selectEditCalc.supplierId
            }
          }
        }

        const response = await this.$api.updateSettings(data)
        if (response.data.status === 'success') {
          this.$parent.$el.scrollTop = 0
          this.isSuccess = true
          this.alert = true
          setTimeout(() => {
            this.isSuccess = false
          }, 2500)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.selectedSupplier = null
        this.selectedSupplierId = null
        this.selectedModel = null
        this.selectEditCalc = null
        this.getAllCalculations()
        this.getSettings()
      }
    },
    closedModal (isReset) {
      this.openModal = false
      if (isReset) {
        this.getSettings()
      }
    },
    getStepLabel (par) {
      const findIndex = this.stepValues.findIndex((item) => item.value === par)
      if (findIndex === -1) {
        if (!par) {
          return ''
        } else {
          return Number(par?.replace('step_', ''))
        }
      }
      const valString = this.stepValues[findIndex].label.slice(0, -1)
      return parseInt(valString)
    },
    async getSteps () {
      try {
        const response = await this.$api.getSteps()
        this.stepValues = response.data.data.stepValues
      } catch (e) {
        console.error(e)
      }
    },
    async getSettings () {
      try {
        this.loading = true
        this.logo = null
        this.calcs = []
        const response = await this.$api.getSetting()
        this.$nextTick(() => {
          this.logo = `${response.data.data.logo}`
        })
        let id = 1
        const settings = response.data.data.settings
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(settings)) {
          this.calcs.push({
            id: id++,
            name: '',
            val: ''
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    onRange (itm) {
      this.selectedCalc = itm
      if (itm.val < 180 || itm.val > 400) {
        this.showConfirmRange = true
      } else {
        this.getValidity()
      }
    },
    updateRange (reset = false) {
      const idx = this.calcs.findIndex(
        (item) => item.id === this.selectedCalc.id
      )
      if (reset) {
        this.calcs[idx].val = ''
      }
      if (this.calcs[idx].val && this.calcs[idx].val < 10) {
        this.alert = true
        this.errorCalcs = []
        this.errorCalcs.push({
          name: 'KalkErr',
          msg: 'Mindestwert: 10%'
        })
      } else {
        this.getValidity()
      }
      this.showConfirmRange = false
    },
    getValidity () {
      this.alert = this.checkStepValues()
    },
    checkStepValues () {
      this.errorCalcs = []
      this.calcs.map((item) => {
        if (!item.val && item.name) {
          this.errorCalcs.push({
            name: 'Kalkulation ' + item.id,
            msg: `Kakulation '${item.name}' erfordert einen Wert`
          })
        }
        if (!item.name && item.val) {
          this.errorCalcs.push({
            name: 'kal' + item.id,
            msg: `Bitte geben Sie einen Namen für ${item.val}`
          })
        }
      })
      return this.errorCalcs.length > 0
    },
    checkEmpty () {
      let empty = true
      this.calcs.map((item) => {
        if (item.val && item.name) {
          empty = false
        }
      })
      return empty
    }
  },
  async created () {
    await this.getSteps()
    await this.getAllCalculations()
    this.getSettings()
    this.getAllCalculations()
  }
}
</script>
<style>
.ml-2{
  margin-left: 8px;
}
.left .ellipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.supplier .ellipse {
  width: 250px;
}

.supplier .no-space {
  width: 250px;
  white-space: nowrap;
}

.model .ellipse {
  width: 200px;
}

@media only screen and (max-width: 1024px) {
  .supplier .ellipse {
    width: 180px;
  }

  .model .ellipse {
    width: 150px;
  }
}
</style>

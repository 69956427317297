<template>
  <div class="w-100">
    <div class="calc-list-item" v-for="(item, index) in items" :key="`${item.modelId}${index}`" style="padding: 30px 40px 20px 40px;">
      <div class="calc-list-item-header">
        <div class="left d-flex">
          <div class="item-cont">
            <div class="item-title">
                Status
            </div>
            <div class="item-desc" :style=" `color:${item.status === 'ausgelaufen' ? '#ee2900': '#00c17e'}`">
              {{ item.status === 'herst. auslauf' ? 'Auslauf' :  item.status}}
            </div>
          </div>
          <div class="item-cont">
            <div class="item-title">
                Gültig ab
            </div>
            <div class="item-desc">
              {{ item.validFrom }}
            </div>
          </div>
          <div class="item-cont">
            <div class="item-title">
                Gültig bis
            </div>
            <div class="item-desc">
              {{ item.validTill }}
            </div>
          </div>
          <div class="item-cont">
            <div class="item-title">
                Teuerungszuschlag
            </div>
            <div class="item-desc">
              {{ item.tz ? 'ja' : 'nein' }}
            </div>
          </div>
          <div class="item-cont">
            <div class="item-title">
                Zusatznr
            </div>
            <div class="item-desc">
              {{ item.zusatznr }}
            </div>
          </div>
          <div class="item-cont">
            <div class="item-title">
                Zusatz
            </div>
            <div class="item-desc">
              {{ item.zusatz ? item.zusatz : '-' }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="icon" @click="openEdit(item, index)" v-if="item.is_editable">
            <ph-note-pencil weight="bold" />
          </div>
          <div class="icon" @click="openDel(item)" v-if="item.is_deletable">
            <ph-trash weight="bold" />
          </div>
        </div>
      </div>
      <div class="calc-list-item-body">
        <div class="left">
          <div class="item-cont">
            <h3 class="text-color-primary text-xs">{{ item.supplierId }}</h3>
            <h3 class="text-color-gray text-sm">{{ item.supplierName }}</h3>
          </div>
          <div class="item-cont">
            <h3 class="text-color-primary text-xs">{{ item.modelId }}</h3>
            <h3 class="text-sm">{{ item.modelName }}</h3>
          </div>
          <div class="item-cont">
            <span class="item-title">Erstelldatum</span>
            <span class="item-desc">{{ item.SPCZCreateDate ? $moment(item.SPCZCreateDate).format('DD.MM.YYYY') : '' }}</span>
          </div>
          <span class="item-cont">
            <span class="item-title">Zuletzt bearbeitet</span>
            <span class="item-desc">
              {{ item.updatedBy.time ? $moment(item.updatedBy.time).format('DD.MM.YYYY') : '' }}
            </span>
          </span>
          <span class="item-cont" v-if="item.isCurrent">
            <span class="item-desc">Aktuelle Preisliste</span>
          </span>
          <div class="d-flex align-items-center" style="height: 30px">
            <div class="user-icon">
              <ph-user class="" :size="18" />
            </div>
            <span class="username"> {{ item.updatedBy ? item.updatedBy.name : '' }}</span>
          </div>
        </div>
        <div class="right" v-if="showStatus" style="width:310px">
            <div class="item-title d-flex">
              <span>Status Preisliste:</span>
              <span class="last-edit" style="color:#555">&nbsp; {{ item.pricelist_status }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PhNotePencil,
  PhTrash,
  PhUser
} from 'phosphor-vue'
export default {
  props: ['items', 'showStatus'],
  components: {
    PhNotePencil,
    PhTrash,
    PhUser
  },
  methods: {
    openEdit (itm, index) {
      this.$emit('openEdit', itm, index)
    },
    openDel (itm) {
      this.$emit('openDel', itm)
    }
  }
}
</script>

<style scoped>
.item-title{
  color: #909090;
  font-weight: lighter;
}

.last-edit {
  font-size: 14px;
  font-weight: bold;
  margin-left: .5em
}
.w-375 {
  min-width:375px
}

.item-desc{
  margin-top: .4em;
}

.item-cont{
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

@media only screen and (max-width: 1024px) {
  .w-375 {
    min-width: 330px;
  }
  .item-cont{
    margin-right: 3em;
    min-width: unset;
  }

}
</style>

<template>
  <div class="w-100 d-flex flex-column align-items-start">
    <div class="d-flex justify-content-center w-100" v-if="isSuccess">
      <div class="alert alert-success">
      Ihre Änderungen wurden gespeichert.
    </div>
    </div>
    <loading
      v-if="loading"
      class="mb-5"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
      "
    />
    <div v-if="!loading" class="card w-100 firmen-card card-white">
      <div class="card-body">
        <div class="fimen-setting">
          <div class="firmen-setting-section">
            <div class="firmen-setting-section-header">
              <p>Individuelles Firmen-Logo</p>
            </div>
            <div class="firmen-setting-section-item">
              <div class="left">
                <h3>LOGO hochladen</h3>
              </div>
              <div class="right">
                <div class="firmen-logo">
                  <img :src="logo" alt="" />
                </div>
              </div>
            </div>
            <div class="border"></div>
            <div
              class="firmen-logo-upload d-flex align-items-center justify-content-between"
            >
              <p class="desc p-0 m-0">
                Bitte wählen Sie eine Datei im Format JPG, GIF oder PNG. Maximale Größe 300KB.
              </p>
              <button @click="modalOpen" class="btn btn-default btn-primary">
                Auswählen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="card w-100 firmen-card card-white">
      <div class="card-body">
        <div class="fimen-setting">
          <div class="firmen-setting-section">
            <div class="firmen-setting-section-header d-flex justify-content-between">
              <p>Individuelle Hauspreise</p>
              <div v-if="alert" class="text-danger" style="margin-right: 2em">
                <p
                  v-for="(error, index) in errorCalcs"
                  class="mb-0"
                  :key="index"
                >
                  {{ error.msg }}
                </p>
              </div>
            </div>
            <div
              v-for="(item, index) in getCalcData"
              :key="index"
              class="firmen-setting-section-item"
            >
              <div class="left">
                <h3>Kalkulation {{ item.id }}</h3>
              </div>
              <div class="right d-flex align-items-center">
                <div class="form-group me-3">
                  <input
                    type="text"
                    placeholder="Set Name"
                    v-model.trim="item.name"
                    @change="getValidity"
                    class="form-control bordered"
                  />
                </div>
                <div class="firmen-input form-group">
                    <input
                      type="number"
                      placeholder="range"
                      v-model.trim="item.val"
                      @change="validate(item)"
                      class="form-control bordered"
                    />
                </div>
                 <a @click.prevent="clearVal(item.id)" class="ml-2 text-color-primary" href="#">
                  <ph-x :size="18" weight="bold" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="w-100 my-3 d-flex align-items-center justify-content-center"
    >
      <button
       :disabled="alert"
        class="btn btn-default btn-primary"
        @click="updateSettings"
      >
        Speichern
      </button>
    </div>
    <confirm-percent-range v-if="showConfirmRange" @closed="updateRange(true)" @update="updateRange" :msg="disMsg" />
    <firmen-upload-modal @changeLogo="changeLogoAfterUpload" @closeModal="closedModal" v-if="openModal" />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue'
import ConfirmPercentRange from './ConfirmPercentRange.vue'
import FirmenUploadModal from '@/components/system/FirmenUploadModal.vue'
import { PhX } from 'phosphor-vue'
export default {
  components: {
    FirmenUploadModal,
    ConfirmPercentRange,
    Loading,
    PhX
  },
  data () {
    return {
      isSuccess: false,
      showConfirmRange: false,
      alert: true,
      clearVals: [],
      logo: null,
      openModal: false,
      loading: true,
      errorCalcs: [],
      stepValues: [],
      selectedCalc: {},
      calcs: [],
      disMsg: {
        num: 24,
        text: 'Sie werden per E-Mail informiert, wenn diese bereit stehen.'
      }
    }
  },
  methods: {
    isHaveClear (index) {
      const isHave = this.clearVals.findIndex(item => item === index)
      if (isHave !== -1) {
        return true
      }
      return false
    },
    changeLogoAfterUpload (val) {
      this.logo = val
    },
    clearVal (id) {
      const idx = this.calcs.findIndex(item => item.id === id)
      this.calcs[idx].name = ''
      this.calcs[idx].val = ''
      this.getValidity()
      const findIndex = this.clearVals.findIndex(item => item === idx)
      if (findIndex === -1) {
        this.clearVals.push(idx)
      }
    },
    modalOpen () {
      this.openModal = true
    },
    async updateSettings () {
      if (this.checkValidity()) {
        return
      }
      try {
        this.loading = true
        let settings = {}
        let tempData = {}

        if (this.errorCalcs.length !== 0) {
          this.alert = true
          return
        }
        for (const index in this.calcs) {
          tempData = {
            ['field' + `${this.calcs[index].id}`]: {
              name: this.calcs[index].name,
              stepValue: this.getStepVal(this.calcs[index].val)

            }
          }
          settings = Object.assign(settings, tempData)
        }
        for (const property in settings) {
          if (!settings[property].name) {
            settings[property] = null
          }
        }
        const data = {
          settings,
          type: 'general'
        }
        const response = await this.$api.updateSettings(data)
        if (response.data.status === 'success') {
          this.$parent.$el.scrollTop = 0
          this.isSuccess = true
          setTimeout(() => {
            this.isSuccess = false
          }, 2500)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.getSettings()
      }
    },
    closedModal (isReset) {
      this.openModal = false
      if (isReset) {
        this.getSettings()
      }
    },
    getStepVal (par) {
      const findIndex = this.stepValues.findIndex(item => item.label === `${par}%`)
      if (findIndex === -1) {
        return `step_${par}`
      }
      return this.stepValues[findIndex].value
    },
    getStepLabel (par) {
      const findIndex = this.stepValues.findIndex(item => item.value === par)
      if (findIndex === -1) {
        if (!par) {
          return ''
        } else {
          return Number(par?.replace('step_', ''))
        }
      }
      const valString = this.stepValues[findIndex].label.slice(0, -1)
      return parseInt(valString)
    },
    async getSteps () {
      try {
        const response = await this.$api.getSteps()
        this.stepValues = response.data.data.stepValues
      } catch (e) {
        console.error(e)
      }
    },
    async getSettings () {
      try {
        this.loading = true
        this.logo = null
        this.calcs = []
        const response = await this.$api.getSetting()
        this.$nextTick(() => {
          this.logo = `${response.data.data.logo}`
        })
        let id = 1
        const settings = response.data.data.settings
        for (const [, value] of Object.entries(settings)) {
          this.calcs.push({
            id: id++,
            name: value.name || '',
            val: value ? this.getStepLabel(value.stepValue) : ''
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    validate (itm) {
      this.selectedCalc = itm
      if (itm.val < 180 || itm.val > 400) {
        this.showConfirmRange = true
      } else {
        this.getValidity()
      }
    },
    updateRange (reset = false) {
      const idx = this.calcs.findIndex(
        (item) => item.id === this.selectedCalc.id
      )
      if (reset) {
        this.calcs[idx].val = ''
      }
      if (this.calcs[idx].val && this.calcs[idx].val < 10) {
        this.alert = true
        this.errorCalcs = []
        this.errorCalcs.push({
          name: 'KalkErr',
          msg: 'Mindestwert: 10%'
        })
      } else {
        this.getValidity()
      }
      this.showConfirmRange = false
    },
    getValidity () {
      this.alert = this.checkValidity()
    },
    checkValidity () {
      this.errorCalcs = []
      this.calcs.map((item) => {
        if ((!item.val && item.name) || (item.val && !item.name)) {
          this.errorCalcs.push({
            name: 'Kalkulation ' + item.id,
            msg: `Kakulation ${item.id} ${item.val ? '' : 'Wert'} ${!item.val && !item.name ? 'und' : ''} ${item.name ? '' : 'name'} erforderlich`
          })
        }
      })
      return this.errorCalcs.length > 0
    }
  },
  computed: {
    getCalcData () {
      return this.calcs
    }
  },
  async created () {
    await this.getSteps()
    this.getSettings()
  }
}
</script>

<style>
.ml-2{
  margin-left: 8px;
}

.card.card-white{
  background-color: white;
}
</style>

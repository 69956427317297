<template>
  <div class="content">
    <perfect-scrollbar class="content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <page-title />
          </div>
          <div class="col-12">
            <div class="page-wrapper">
                <div class="page-wrapper-header d-flex justify-content-between">
                <div class="page-wrapper-title">
                  <p class="text-color-65-gray text-xs mb-0">
                    <span><strong>133</strong> Rabatte</span>
                  </p>
                </div>
                <div class="d-flex">
                  <div class="page-sorting-items">
                    <span class="me-3 text-xs text-color-65-gray"
                      >Sortierung</span
                    >
                    <select
                      v-model="sorting"
                      class="form-control"
                    >
                      <option selected value="AZ">Lieferanten Alphabetisch</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="page-wrapper-body">
                  <div class="rabatte-items">
                      <div v-for="i in 8" :key="i" class="card item">
                            <div class="card-body item-body">
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <div class="item-img">
                                            <img src="/imgs/dummy_rabatte_img.png" alt="">
                                        </div>
                                    </div>
                                    <div class="col-10">
                                        <div class="item-sum-detail d-flex align-items-center justify-content-between">
                                            <div class="left d-flex align-items-center">
                                                <div class="detail-item">
                                                    <span>214</span>
                                                    <span class="title">Active KS</span>
                                                </div>
                                                <div class="detail-item">
                                                    <span>Warengruppe</span>
                                                    <span class="title">Polstergarnitur</span>
                                                </div>
                                                <div class="detail-item">
                                                    <span>444306</span>
                                                    <span class="title">BECO</span>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="detail-right-item">
                                                    <span>View Rabatte</span>
                                                    <a @click.prevent="openDetail(i)" href="#">
                                                        <ph-x v-if="opened === i" :size="22" />
                                                        <ph-plus v-else :size="22" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-11">
                                        <div class="rabatte-item-desc" :class="{'d-none' : opened !== i}">
                                            <div class="rabatte-item-desc-item">
                                                <strong class="d-block">Lieferung:</strong>
                                                <ul>
                                                    <li>Frei Haus incl. Entladung ohne Zusatzkosten</li>
                                                    <li>für Deutschland: ab 10 Rahmen oder 300 EUR MAM</li>
                                                    <li>für Benelux, Österreich, Südtirol: 500 EUR MAM</li>
                                                    <li>für Schweiz: 5 % vom Netto-Warenwert, unversteuert inkl. Verzollung</li>
                                                </ul>
                                            </div>
                                            <div class="rabatte-item-desc-item">
                                                <strong class="d-block">Platzierungsrabatt:</strong>
                                                <ul>
                                                    <li>2 Rahmen pro Typ ohne Berechnung für Studiobau</li>
                                                    <li>Motorrahmen 50 % für Studio</li>
                                                    <li>Teuerungszuschlag in Prozent: 9,00</li>
                                                    <li>Teuerungszuschlag gültig von: 01.08.2021</li>
                                                    <li>Teuerungszuschlag gültig bis: 30.06.2022</li>
                                                    <li>Teuerungszuschlag Information: Der Preis inkl. TZ wird auf Rechnung und AB ausgewiesen. Neue Einkaufspreislisten werden abgestellt</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'
import { mapMutations } from 'vuex'
import { PhPlus, PhX } from 'phosphor-vue'
export default {
  components: {
    PageTitle,
    PhPlus,
    PhX
  },
  data () {
    return {
      sorting: 'AZ',
      opened: 1
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB']),
    openDetail (idx) {
      if (idx === this.opened) {
        this.opened = 0
        return
      }
      this.opened = idx
    }
  },
  created () {
    const data = [
      {
        title: 'Rabatte'
      }, {
        title: ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
  }
}
</script>

<style>

</style>

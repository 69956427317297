<template>
   <div class="vme-modal">
    <div class="spcz-modal-content">
      <error-screen
        @goBack="resetAlert"
        :msg="alert.msg"
        style="margin-top: 8em"
        v-if="alert.status"
      ></error-screen>
      <div class="vme-modal-content-body">
          <div v-if="!alert.status" class="spcz-container">
            <div class="card">
              <div class="card-body">
                <div class="border-0 flex-column align-items-start">
                  <div class="title vme-modal-content-header item-left d-flex flex-column position-fixed spcz-head">
                    <div class="d-flex justify-content-between">
                      SPCZ Datei hochladen
                      <a @click.prevent="closeModal" class="close-icon" href="#">
                        <ph-x :size="24" />
                      </a>
                    </div>
                    <div class="desc">Es sind nur SPCZ Dateien erlaubt</div>
                  </div>
                  <div class="item-right w-100">
                    <div
                      @dragover.prevent="onDragOver"
                      @dragleave.prevent="onDragLeave"
                      @drop.stop.prevent="onDrop"
                      class="vme-upload w-100"
                    >
                      <input
                        class="d-none"
                        accept=".spcz"
                        max-file-size="1"
                        name="files"
                        ref="input"
                        type="file"
                        @change="onChange($event)"
                      />
                      <div v-if="uploadStatus === 'ready'">
                        <div class="text-sm my-3 text-color-gray">
                          <span class="me-2 font-bold">Dateiname:</span>
                          <span>{{ form.fileName }}</span>
                        </div>
                        <div>
                          <button
                            @click="changeFile"
                            class="btn btn-primary py-2 px-4 mt-3"
                          >
                            andere Datei hochladen
                          </button>
                        </div>
                      </div>
                      <div v-if="uploadStatus === 'not-started'">
                        <div class="upload-icon">
                          <ph-file-arrow-up
                            :class="{ animated: isDraggedOver }"
                            :size="48"
                          />
                        </div>
                        <div
                          class="d-flex justify-content-between align-items-center vme-upload-footer"
                        >
                          <div class="d-flex flex-column">
                            <p>SPCZ Datei per Drag & Drop hinzufügen</p>
                            <span>oder eine Datei auswählen</span>
                          </div>
                          <button @click="chooseFile" class="btn btn-white">
                            <ph-plus
                              :size="16"
                              weight="bold"
                              class="me-2 mb-1"
                            />Datei auswählen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div class="flex-grow-1">
                    <div class="item w-100">
                      <div class="item-left">Status</div>
                      <div class="item-right">
                        <div class="form-group mb-0">
                          <select
                            placeholder="Status"
                            v-model="form.status"
                            :disabled="!models.selected"
                            @change="fetchPriceList"
                            name=""
                            id=""
                            class="form-control bordered"
                          >
                            <option value="" selected hidden>auswählen</option>
                            <option value="neuanlage">NEU</option>
                            <option value="preisverlängerung">PVERL</option>
                            <option value="preisänderung">PAEND</option>
                            <option value="div. änderungen">UPDATE</option>
                            <option value="ergänzung">ERGAENZUNG</option>
                            <option value="herst. auslauf">AUSLAUF</option>
                            <option value="Typenänderung">TAEND</option>
                            <option value="maßänderung">MASSAEND</option>
                            <option value="konditionsänderung">KONDAEND</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="item w-100">
                      <div class="item-left">Gültig ab</div>
                      <div class="item-right">
                        <div class="form-group mb-0">
                          <date-picker
                            @change="validDate"
                            placeholder="dd.mm.yyyy"
                            :disabled="!models.selected"
                            format="DD.MM.YYYY"
                            :formatter="myFormat"
                            v-model="form.validFrom"
                            valueType="format"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="item w-100">
                      <div class="item-left">Gültig bis</div>
                      <div class="item-right">
                        <div class="form-group mb-0">
                          <date-picker
                            @change="closedDate"
                            placeholder="dd.mm.yyyy"
                            :disabled="!models.selected"
                            format="DD.MM.YYYY"
                            :formatter="myFormat"
                            v-model="form.validTill"
                            valueType="format"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="item w-100">
                      <div class="item-left">Teuerungszuschlag</div>
                      <div class="item-right">
                        <div class="form-group mb-0">
                          <select v-model="form.tz"
                            :disabled="!models.selected" class="form-control bordered" @change="fetchPriceList">
                              <option value="" selected hidden>auswählen</option>
                              <option value="nein">nein</option>
                              <option value="ja">ja</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" style="width:305px">
                      <div class="item-left">Zusatznr.</div>
                      <div class="item-left">Zusatz</div>
                    </div>
                    <div class="d-flex justify-content-between" style="width:500px">
                      <div class="w-50">
                        <div class="item-right">
                          <div class="form-group mb-0">
                            <select v-model="form.zusatznr" class="form-control bordered">
                              <option v-for="index in 25" :key="index" :value="index" :disabled="zusats.includes(index)">{{ index }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="w-50">
                        <div class="item-right">
                          <div class="form-group mb-0">
                            <input v-model="form.zusatz" class="form-control bordered" @input="onZusatz" style="padding-left:10px" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="priceList.length > 0" class="item-left mt-4">
                      <span>Zuvor erstellte Varianten dieser Preisliste</span>
                    </div>
                    <div v-for="price in priceList" :key="price.zusatznr">
                      <div class="d-flex cursor-pointer spcz-table" @click="form.zusatz=price.zusatz; form.zusatznr=price.zusatznr">
                        <span style="min-width:245px">{{ price.zusatznr}}</span>
                        <span>{{ price.zusatz }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="border-0 flex-column align-items-start flex-grow-1">
                    <div class="item-left">Modell Info</div>
                    <div class="item-right w-100 h-100">
                      <div class="form-group w-100 mt-3">
                        <textarea
                          :disabled="!models.selected"
                          v-model="form.info"
                          cols="40"
                          rows="10"
                          class="text-area form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <button
                    @click="updateForm"
                    :disabled="!models.selected"
                    style="min-width: 120px"
                    class="btn btn-primary btn-md btn-default mt-3"
                  >
                    <loading v-if="loading" :size="'sm'" style="width: 100%;height: 20px;"/>
                    <span v-else>Speichern</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorScreen from '@/components/common/ErrorScreen.vue'
import { PhFileArrowUp, PhPlus, PhX } from 'phosphor-vue'
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/common/Loading.vue'

export default {
  props: ['models', 'suppliers'],
  components: {
    ErrorScreen,
    PhX,
    DatePicker,
    PhFileArrowUp,
    PhPlus,
    Loading
  },
  data () {
    return {
      loading: false,
      dragStart: false,
      isDraggedOver: false,
      value: null,
      alert: {
        status: false,
        msg: null
      },
      uploadStatus: 'not-started',
      priceList: [],
      zusats: [],
      form: {
        tz: 'nein',
        fileName: null,
        supplierId: null,
        status: '',
        zusatznr: 1,
        zusatz: '',
        modelId: null,
        modelInfo: null,
        file: null,
        validFrom: '',
        validTill: ''
      },
      myFormat: {
        // Date to String
        stringify: date => {
          return date ? this.$moment(date).format('DD.MM.YYYY') : null
        },
        // String to Date
        parse: value => {
          return value ? this.$moment(value, 'DD/MM/YYYY').toDate() : null
        }
      }
    }
  },
  watch: {
    priceList (old, newa) {
      this.zusats = []
      this.priceList.forEach(itm => {
        this.zusats.push(parseInt(itm.zusatznr, 10))
      })
      this.form.zusatznr = this.zusats.length > 0 ? this.zusats[this.zusats.length - 1] + 1 : 1
    }
  },
  methods: {
    resetAlert (par) {
      if (!par) {
        this.alert.status = false
        this.alert.msg = null
      }
    },
    closeModal () {
      this.$emit('closed')
    },
    async fetchPriceList () {
      if (this.form.validTill && this.form.validFrom && this.form.status) {
        this.loading = true
        try {
          const response = await this.$api.getPricelist(this.form)
          if (response.status === 200) {
            this.priceList = response.data.data
          }
        } catch (err) {
          this.alert.status = true
          this.alert.msg = 'Gültig ab Datum muss vor dem Gültig bis Datum liegen'
        }
        this.loading = false
      }
    },
    validDate (date) {
      const vDate = date.split('.')
      const vfDate = parseInt(vDate[2], 10)
      if (vfDate < 2000 || vfDate > 2099) {
        this.form.validFrom = `${vDate[0]}.${vDate[1]}.20${vfDate.toString().slice(-2)}`
      }
      this.fetchPriceList()
    },
    closedDate (date) {
      const tDate = date.split('.')
      const vtDate = parseInt(tDate[2], 10)
      if (vtDate < 2000 || vtDate > 2099) {
        this.form.validTill = `${tDate[0]}.${tDate[1]}.20${vtDate.toString().slice(-2)}`
      }
      this.fetchPriceList()
    },
    selectOptionSupplier (selectedOption) {
      this.form.supplierId = selectedOption.supplierId
    },
    changeFile () {
      this.form.file = null
      this.form.formName = null
      this.uploadStatus = 'not-started'
    },
    onDragOver () {
      this.dragStart = true
      this.isDraggedOver = true
    },
    onDragLeave () {
      this.dragStart = false
      this.isDraggedOver = false
    },
    chooseFile () {
      this.$refs.input.click()
    },
    onChange (e) {
      const fileList = e.target.files
      this.controlFile(fileList[0])
    },
    addLocalFiles (fileList) {
      const files = Array.from(fileList)
      this.controlFile(files[0])
    },
    onZusatz (e) {
      const txt = e.target.value
      if (txt.length > 15) {
        this.form.zusatz = txt.slice(0, 15)
      }
    },
    onDrop (e) {
      const fileList = e.dataTransfer.files
      if (fileList) {
        this.addLocalFiles(fileList)
      }
      this.onDragLeave()
    },
    async controlFile (file) {
      this.form.fileName = file.name
      this.form.file = file
      this.uploadStatus = 'ready'
    },
    async updateForm () {
      try {
        if (!this.form.status) {
          this.alert.status = true
          this.alert.msg = 'Status ist erforderlich.'
          return
        }
        if (!this.form.tz) {
          this.alert.status = true
          this.alert.msg = 'Teuerungszuschlag ist erforderlich.'
          return
        }
        if (!this.form.validFrom) {
          this.alert.status = true
          this.alert.msg = 'Gültig ab ist erforderlich..'
          return
        }
        if (!this.form.validTill) {
          this.alert.status = true
          this.alert.msg = 'Gültig bis ist erforderlich..'
          return
        }

        if (!this.models.selected.modelId) {
          this.alert.status = true
          this.alert.msg = 'Model is ist erforderlich..'
          return
        }
        if (!this.suppliers.selected.supplierId) {
          this.alert.status = true
          this.alert.msg = 'Supplier is ist erforderlich.'
          return
        }
        if (!this.form.file) {
          this.alert.status = true
          this.alert.msg = 'Das Hochladen einer spcz-Datei ist erforderlich.'
          return
        }
        const fileSize = this.form.file.size / 1024 / 1024
        if (fileSize > 350) {
          this.alert.status = true
          this.alert.msg = 'File size must be no more than 350mb'
          return
        }
        this.$parent.$el.scrollTop = 0
        this.loading = true
        const formData = new FormData()
        formData.append('scanprix_file', this.form.file)
        formData.append('supplierId', this.suppliers.selected.supplierId)
        formData.append('modelId', this.models.selected.modelId)
        formData.append('validFrom', this.form.validFrom)
        formData.append('validTill', this.form.validTill)
        formData.append('status', this.form.status)
        formData.append('modelInfo', this.form.info)
        formData.append('zusatznr', this.form.zusatznr)
        formData.append('zusatz', this.form.zusatz)
        if (this.form.tz === 'ja') {
          formData.append('tz', true)
        }
        if (this.form.tz === 'nein') {
          formData.append('tz', false)
        }

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
        const response = await this.$api.updateForm(formData, config)
        if (response.status === 200) {
          this.form.supplierId = this.suppliers.selected.supplierId
          this.form.modelId = this.models.selected.modelId
          this.$emit('success', this.form)
        }
      } catch (e) {
        this.alert.status = true
        this.loading = false
        if (e.response.data.message === 'body.validTill must be greater than body.validFrom') {
          this.alert.msg = 'Gültig ab Datum muss vor dem Gültig bis Datum liegen'
        } else if (e.response.data.message === 'body.scanprix_file must be a valid application/octet-stream file') {
          this.alert.msg = 'Nur Dateien im Format SPCZ sind erlaubt'
        } else {
          this.alert.msg = 'System Error'
        }
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    this.form.modelId = this.models.selected.modelId
    this.form.supplierId = this.suppliers.selected.supplierId
  }
}
</script>
<style lang="scss" scoped>
.spcz-head {
  top: 3%;
  padding-top:40px;
  width: 66%;
  z-index: 100;
  background: white;
  @media only screen and (max-width: 1024px) {
    top: 1%;
    width: 68%;
  }
}

.spcz-table {
  width: 465px;
  margin-top: 1em;
  border: solid 1px #c8c8c8;
  border-radius: 5px;
  padding: .3em;
  padding-left: .8em;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%)
}
.spcz-table:hover {
  background-color: #fafafa
}
</style>
